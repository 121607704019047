import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./TableProduct";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";
import { CustomField } from "../client";
import { CategoryLogo } from "../../assets/Svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";

import {
  getCategories,
  getCustomerLedgers,
  getData,
  getStock,
  getSupplier,
  saveProduct,
  updateProduct,
} from "../../apiservices";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SimpleTable from "./SimpleTable";
import Search from "../searchBar";
import { formatDate } from "../general";
const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  marginRight: 10,
  color: "#52b335",
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});

export const schema = yup
  .object({
    modalName: yup.string().required(),
    price: yup.string().required(),
    salePrice: yup.string().required(),
    stock: yup.string().required(),
  })
  .required();

const Product = () => {
  const {
    setValue,
    // formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  let [dataObj, setDataObj] = useState({ description: "" })

  const [state, setState] = useState({
    openModal: false,
    brandName: "",
    arrayList: [],
    productData: [],
    check: false,
    opening_qty: 0,
    productFieldData: [],
    category_id: 0,
    product: "",
    selectedProduct: "",
    categoriesArr: [],
    supplierData: [],
    customerData: [],
    detailsData: {},
  });

  const getSuppliersData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    let obj = {}
    let res = await getSupplier();
    if (res.success) {
      setState((prev) => ({ ...prev, supplierData: res.data }));
    }
  };

  const getCustomersData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    const obj = {
      table: "clients",
      // filters: [
      //   {
      //     column_name: "company_code",
      //     operation: "=",
      //     value: dataX.company_code
      //   }
      // ],
    }
    let res = await getData(obj);
    if (res.success) {
      setState((prev) => ({ ...prev, customerData: res.data }));
    }
  };
  
  useEffect(() => {
    getSuppliersData();
    getCustomersData();
  }, []);

  const handleModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      check: false,
      updatedData: data,
      stockQuantity: data?.currentStock,
      brand: data?.brand,
      selectedProduct: data?.itemName,
    }));
    if (data.description) {
      setDataObj(data)
    }
  };
  const openDetail = async (data = "") => {
    if (data.id) {
      const pDataObj = {
        table: "purchases",
        filters: [
          {
            column_name: "product_id",
            operation: "=",
            value: data.id
          }
        ],
      }
      const purchaseRes = await getData(pDataObj)
      // console.log("purchaseRes", purchaseRes)

      const sDataObj = {
        table: "sales",
        filters: [
          {
            column_name: "product_id",
            operation: "=",
            value: data.id
          }
        ],
      }
      const saleRes = await getData(sDataObj)
      // console.log("saleRes", saleRes)

      let stock = []
      for (var d = new Date('2024-03-01'); d <= new Date(); d.setDate(d.getDate() + 1)) {
        if (purchaseRes && purchaseRes.data && purchaseRes.data.length) {
          // console.log("d--------------------", formatDate(d));
          purchaseRes.data.map((element) => {
            if (formatDate(element.date) == formatDate(d)) {
              stock.push({
                date: element.date,
                party: state.supplierData.filter(supplier => supplier.id == element.supplier_id)[0].name,
                remarks: `In  ${(element.voucher_no.toString().length == 1 ? "Purchase#00" : element.voucher_no.toString().length == 2 ? "Purchase#0" : "Purchase#") + element.voucher_no}`,
                in: element.qty,
                out: 0
              })
              // console.log(element)
            }
          })
        }
        if (saleRes && saleRes.data && saleRes.data.length) {
          saleRes.data.map((element) => {
            if (formatDate(element.date) == formatDate(d)) {
              stock.push({
                date: element.date,
                party: state.customerData.filter(customer => customer.id == element.customer_id)[0].client_name,
                remarks: `Out ${(element.voucher_no.toString().length == 1 ? "Invoice#00" : element.voucher_no.toString().length == 2 ? "Invoice#0" : "Invoice#") + element.voucher_no}`,
                in: 0,
                out: element.qty,
              })
              // console.log(element)
            }
          })
        }
      }
      if (stock && stock.length) {
        for (let i = 0; i < stock.length; i++) {
          if (i == 0){
            stock[i].closing = parseInt(data.opening_qty) + stock[i].in - stock[i].out
          }
          else{
            stock[i].closing = stock[i-1].closing + stock[i].in - stock[i].out
          }
        }
      }

      setState((prev) => ({
        ...prev,
        detailModal: !prev.detailModal,
        detailsData: stock,
      }));
    }

  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setDataObj((prev) => ({ ...prev, [name]: value }));
  };
  const getProducts = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      company_code: userData.company_code,
    };
    let res = await getStock();
    if (res.success) {
      const data = res.data.map(x => {
        return ({
          ...x,
          stock: (parseInt(x.opening_qty) + parseInt(x.stock_in) - parseInt(x.stock_out))
        })
      })
      setState((prev) => ({
        ...prev,
        productData: data
      }));
    }
  };

  useEffect(() => {
    getProducts();
  }, [state.categoriesArr]);

  useEffect(() => {
    getCategory();
  }, [])
  
  const onSubmit = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (state.check) {
      let { id, brand_name, category_name, stock, stock_in, stock_out, ...obj } = dataObj
      obj.updated_by = userData.id
      let res = await updateProduct(obj, id);
      if (res) {
        toast.success("Product updated successfully");
        getProducts();
        handleModal();
      }
    } else {
      let { id, brand_name, category_name, stock, stock_in, stock_out, ...obj } = dataObj
      obj.added_by = userData.id
      let res = await saveProduct(obj);
      if (res) {
        toast.success("product saved successfully");
        getProducts();
        handleModal();
      }
    }
    setDataObj({ description: "" })
  };
  const getCategory = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    let obj = {
      
    };
    let res = await getCategories(obj);
    if (res.success) {
      setState((prev) => ({
        ...prev,
        categoriesArr: res.data,
      }));
    }
  };
  const handleCheck = () => {
    setState((prev) => ({ ...prev, check: !prev.check }));
  };
  const handleStock = () => {
    setState((prev) => ({
      ...prev,
      stock: !prev.stock,
    }));
    setValue("stock", Number(state.addStock) + Number(state.stockQuantity));
  };
  // const handleOptionChange = (event, newOption) => {
  //   if (newOption) {
  //     setState((prev) => ({
  //       ...prev,
  //       selectedProduct: newOption,
  //       product: newOption,
  //     }));
  //   } else {
  //     setState((prev) => ({
  //       ...prev,
  //       selectedProduct: "",
  //       product: "",
  //     }));
  //   }
  // };
  // const handleInputChange = (event, newInputValue) => {
  //   if (newInputValue != "undefined") {
  //     if (newInputValue) {
  //       setState((prev) => ({ ...prev, product: newInputValue }));
  //     }
  //   }
  // };
  // const updateData = (data) => {
  //   setState((prev) => ({ ...prev, productData: data }));
  // };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      <>
        <Layout
          justifyContent="space-between"
          alignItems={"center"}
          direction={"row"}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "24px",
              fontWeight: 700,
            }}></Typography>
          <Typography sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
            Products
          </Typography>
          <Btn onClick={handleModal}>
            <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new products
          </Btn>
        </Layout>
        <Stack sx={{ padding: "20px" }}>
          <DataTable
            editProduct={(data) => handleModal(data)}
            data={state.productData}
            handleCheck={handleCheck}
            getData={getProducts}
            openDetail={openDetail}
          />
        </Stack>
      </>
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ color: "#52b335" }}>
              <CategoryLogo />
            </Box>
            <Typo>Add new Product</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack gap={2} sx={{ mb: 2 }}>
              <Stack>
                <CustomField
                  fullWidth
                  select
                  name="category_id"
                  value={dataObj.category_id}
                  onChange={(handleChange)}
                  label="Select Category">
                  {state.categoriesArr.map((option) => {
                    return (<MenuItem key={option.id} value={option.id}>
                      {`(${option.brand_name}) ${option.category_name}`}
                    </MenuItem>)
                  })}
                </CustomField>
              </Stack>
            </Stack>
            <Stack gap={2} sx={{ mb: 2 }}>
              <Stack>
                <CustomField
                  fullWidth
                  label="Model Name"
                  name="description"
                  value={dataObj.description}
                  onChange={handleChange}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}
                  type="text"
                />
              </Stack>
              <Stack>
                <CustomField
                  fullWidth
                  label="Purchase Price(Rs)"
                  name="purchase_price"
                  value={dataObj.purchase_price}
                  onChange={handleChange}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}
                  type="number"
                />
              </Stack>
            </Stack>
            <Stack gap={2} sx={{ mb: 3 }}>
              <Stack>
                <CustomField
                  fullWidth
                  label="Sale Price(Rs)"
                  name="sale_price"
                  value={dataObj.sale_price}
                  onChange={handleChange}
                  sx={{
                    background: "#F6F6F6",
                    "& label.Mui-focused": {
                      color: "#52b335",
                    },
                  }}
                />
              </Stack>
              {state.check ? (
                <Stack>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-password"
                    name="opening_qty"
                    onChange={handleChange}
                    value={dataObj.opening_qty}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleStock}>
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      background: "#F6F6F6",
                      "& label.Mui-focused": {
                        color: "#52b335",
                      },
                    }}
                    label="Opening Stock"
                    type="number"
                  />
                </Stack>
              ) : (
                <Stack>
                  <CustomField
                    fullWidth
                    id="outlined-select-currency"
                    name="opening_qty"
                    onChange={handleChange}
                    label="Current Stock"
                    sx={{
                      background: "#F6F6F6",
                      "& label.Mui-focused": {
                        color: "#52b335",
                      },
                    }}
                    type="number"
                  />
                </Stack>
              )}
            </Stack>

            <Stack spacing={3} direction={"row"}>
              <Button
                onClick={handleModal}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "200px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                size="small"
                sx={{
                  width: "200px",
                  textTransform: "none",
                  background: "#52b335",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {state.check ? "update product" : " Save product"}
              </Button>
            </Stack>
          </Stack>
        }
      />
      <CustomDialogue
        open={state.detailModal}
        handleClose={openDetail}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typo>Summary</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            <Stack sx={{ mt: 2 }}>
              <SimpleTable dataTable={state.detailsData} />
            </Stack>
            <Btn
            sx={{ height: '30px', mt: '10px', mr: '5px', padding: 0 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setState((prev) => ({
                ...prev,
                detailModal: false,
              }));
            }}
            >Close</Btn>
          </Stack>
        }
      />
    </Stack>
  );
};

export default Product;
