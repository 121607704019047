import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, MenuItem, Stack, Typography } from "@mui/material";
import CustomDialogue from "../reuseableComp/Modal";
import { Typo } from "../payment";
import { CustomField } from "../client";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { getBanks, updateDataGeneral } from "../../apiservices";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#52b335",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SimpleTable({ data, viewExpense, getExpensesList }) {
  const [expenseData, setExpenseData] = React.useState({
    id: 0,
    selectedMode: "",
    selectedBank: "",
    accId: 0,
    remarks: "",
    amount: 0,
  })
  // const [accountInfo, setAccountInfo] = React.useState("")
  const [state, setState] = React.useState({
    check: false,
    accountsArr: [],
    banksList: [],
    date_expense: new Date(),
  });
  const [openModalExpense, setOpenModalExpense] = React.useState(false)

  const handleModalExpense = () => {
    setOpenModalExpense(false)
  }

  const getBanksList = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {

    };
    let res = await getBanks(obj);
    if (res.success) {
      let arr = [];
      res.data.map((item) => arr.push(item.bank_name));
      // const accInfo = res.data && res.data.length > 0 && res.data.filter((x) => x.id == state.acc_id)[0]
      // if (accInfo) {
      //   setAccountInfo(`${accInfo.account_title} [${accInfo.account_number}]`)
      // }
      const arrayWithDuplicates = arr;
      const uniqueArray = [...new Set(arrayWithDuplicates)];
      setState((prev) => ({
        ...prev,
        banksList: res.data,
        uniBanksArr: uniqueArray,
      }));
    }
  };

  const submitFormExpense = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    debugger
    let obj = {
      transaction_mode: expenseData.selectedMode,
      acc_id: (expenseData.selectedMode == "Bank" ? state.accId : 0),
      remarks: expenseData.remarks,
      amount: expenseData.amount,
      updated_by: userData.id
    };
    if(obj.transaction_mode == "Bank" && obj.acc_id == 0){
      toast.error("Fill Bank and Account");
      return
    }
    const dataX = {
      table: "expenses",
      data: obj,
      filters: [
        {
          column_name: "id",
          operation: "=",
          value: expenseData.id
        }
      ]
    }
    let res = await updateDataGeneral(dataX);
    if (res) {
      toast.success("Expense updated successfully");
      handleModalExpense();
      viewExpense()
      getExpensesList()
    }
  };

  const handleChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    if (name === "selectedBank") {
      setExpenseData((prev) => ({ ...prev, selectedBank: value }))
      let arr = [];
      let filtered = state.banksList.filter((item) => item.bank_name === value);
      filtered.map((item) => arr.push(item.address));
      setState((prev) => ({ ...prev, accountsArr: filtered }));
    }
    if (name === "selectedAcc") {
      // state.banksList.map(
      //   (item) =>
      //     item.account_number === value &&
      //     setState((prev) => ({ ...prev, accId: item.id }))
      // );
      setExpenseData((prev) => ({ ...prev, accId: value }))
    }
    // if (name === "selectedClient") {
    //   let filtered = state.clientData.filter(
    //     (item) => item.clientName === value
    //   );
    //   setState((prev) => ({
    //     ...prev,
    //     prevBalance: filtered[0].currentBalance,
    //   }));
    // }
  };

  React.useEffect(() => {
    getBanksList();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Date</StyledTableCell>
              <StyledTableCell align="left">Transaction Mode</StyledTableCell>
              <StyledTableCell align="left">Account</StyledTableCell>
              <StyledTableCell align="left">Remarks</StyledTableCell>
              <StyledTableCell align="right">Amount</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <StyledTableRow key={1} onClick={() => {
                let sBank = null
                if(row.transaction_mode == "Bank"){
                  sBank = state.banksList && state.banksList.length > 0 && state.banksList.filter((option) => option.id == row.acc_id)[0].bank_name
                  let arr = [];
                  let filtered = state.banksList.filter((item) => item.bank_name === sBank);
                  filtered.map((item) => arr.push(item.address));
                  setState((prev) => ({ ...prev, accountsArr: filtered }));
                }
                setExpenseData({
                  id: row.id,
                  selectedMode: row.transaction_mode,
                  selectedBank: sBank ? sBank : "",
                  accId: row.acc_id,
                  remarks: row.remarks,
                  amount: row.amount
                })
                setOpenModalExpense(true)
              }}>
                <StyledTableCell align="left">
                  {row.date}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.transaction_mode}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {state.banksList.map((option) => {
                    if (option.id == row.acc_id) {
                      return `${option.account_title} [${option.account_number}]`
                    }
                  })}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.remarks}
                </StyledTableCell>
                <StyledTableCell align="right">{row.amount}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomDialogue
        open={openModalExpense}
        handleClose={handleModalExpense}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <AccountBalanceWalletIcon
              sx={{ color: "#52b335" }}
              fontSize="large"
            />
            <Typo>Update Expense</Typo>
          </Stack>
        }
        content={
          <Stack sx={{ padding: 1 }}>
            {/* <CustomField
              fullWidth
              id="date_expense"
              label="Date"
              onChange={(e)=>{
                setValue("date_expense", e.target.value);
              }}
              sx={{ mb: 2 }}
              type="date"
            />
            <Typography sx={{ color: "red" }}>
              {errors.date?.message}
            </Typography> */}

            <CustomField
              fullWidth
              id="outlined-select-currency"
              select
              label="Select Transaction Mode"
              name="selectedMode"
              value={expenseData.selectedMode}
              onChange={(e) => {
                setExpenseData((prev) => ({
                  ...prev,
                  selectedMode: e.target.value,
                }))
              }}
              sx={{ mb: 2 }}>
              {["Cash", "Bank"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </CustomField>
            {expenseData.selectedMode === "Bank" && (
              <>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Bank Name"
                  name="selectedBank"
                  value={expenseData.selectedBank}
                  onChange={handleChange}
                  sx={{ mb: 2 }}>
                  {state.uniBanksArr.map((option) => (
                    <MenuItem key={option} value={option}>
                      {`${option}`}
                    </MenuItem>
                  ))}
                </CustomField>
                <CustomField
                  fullWidth
                  id="outlined-select-currency"
                  select
                  label="Select Account"
                  name="selectedAcc"
                  value={expenseData.accId}
                  onChange={handleChange}
                  sx={{ mb: 2 }}>
                  {state.accountsArr.map((option, id) => (
                    <MenuItem
                      key={option.account_number}
                      value={option.id}>
                      {`${option.account_title} [${option.account_number}]`}
                    </MenuItem>
                  ))}
                </CustomField>
              </>
            )}

            <CustomField
              fullWidth
              id="remarks_expense"
              label="Remarks"
              onChange={(e) => {
                setExpenseData((prev) => ({
                  ...prev,
                  remarks: e.target.value,
                }));
              }}
              sx={{ mb: 2 }}
              value={expenseData.remarks}
            />
            <Typography sx={{ color: "red" }}>
              {/* {errors.remarks_expense?.message} */}
            </Typography>

            <CustomField
              fullWidth
              id="amount_expense"
              label="Amount"
              onChange={(e) => {
                setExpenseData((prev) => ({
                  ...prev,
                  amount: e.target.value,
                }));
              }}
              value={expenseData.amount}
              sx={{ mb: 2 }}
              type="number"
            />
            <Typography sx={{ color: "red" }}>
              {/* {errors.amount_expense?.message} */}
            </Typography>



            <Stack spacing={3} direction={"row"} sx={{ mt: 3 }}>
              <Button
                size="small"
                onClick={submitFormExpense}
                sx={{
                  width: "250px",
                  textTransform: "none",
                  background: "#52b335",
                  color: "#FFF",
                  "&:hover": {
                    background: "#61D140",
                  },
                }}>
                {"Update expense details"}
              </Button>
              <Button
                onClick={handleModalExpense}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "250px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        }
      />
    </>
  );
}
